import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { adminProfile, clientProfile } from '../../services/admin';

// Actions

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  data: [],
};

const slice = createSlice({
  name: 'travel',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // END LOADING
    endLoading(state) {
      state.isLoading = true;
    },
    setTravel(state , action){
      state.data = action.payload
    }
  },
  // extraReducers: (builder) => {
  //   builder

  //     .addCase(getAdminProfile.pending, (state, action) => {
  //       state.isLoading = true;
  //     })
  //     .addCase(getAdminProfile.rejected, (state, action) => {
  //       state.isLoading = false;
  //     })
  //     .addCase(getAdminProfile.fulfilled, (state, action) => {
  //       state.isLoading = false;
  //       state.data = action.payload;
  //     });
  // },
});

// Reducer
export default slice.reducer;
// Actions
export const {
  setTravel,
} = slice.actions;

