import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { separateNumberWithComma } from '../../utils/index';
import persianToEnglishNumber from '../../utils/persianToEnglishNumber';

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
};

export default function RHFTextField({
  name,
  type,
  min,
  max,
  onChange,
  disableComma,
  isPhone,
  label,
  required,
  withZero,
  ...other
}) {
  const { control } = useFormContext();
  const isNumber = type === 'number';
  if (isNumber) type = 'string';
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: change, value, ...field }, fieldState: { error } }) => {
        const changeValue = isNumber && !disableComma ? separateNumberWithComma(value) : value;

        // console.log(`RHFTextField - ${name}`, { changeValue });
        const handleChange = (e) => {
          if (isNumber) {
            let newValue = e.target.value?.replace(/,/g, '')?.trim();
            if (newValue)
              newValue = isPhone || withZero ? persianToEnglishNumber(newValue) : +persianToEnglishNumber(newValue);
            const regex = /^[0-9]+$/;
            // const regex = /^[0-9\u0660-\u0669\u06F0-\u06F9]+$/;
            console.log(`RHFTextField - ${name} - handleChange`, {
              value: e.target.value,
              disableComma,
              changeValue,
              newValue,
              min,
              max,
            });
            if (regex.test(newValue) && !Number.isNaN(newValue)) {
              if (min >= 0) {
                if (newValue < min) {
                  newValue = min;
                }
              }
              if (max >= 0) {
                if (newValue > max) {
                  newValue = max;
                }
              }
            }
            console.log(`RHFTextField - ${name} - handleChange`, {
              newValue,
            });
            onChange?.(newValue);
            change(newValue);
          } else {
            onChange?.(e);
            change(e);
          }
        };

        return (
          <TextField
            {...field}
            // inputProps={{
            //   max: 2,
            // }}
            label={
              <>
                {label}
                {required ? <span className="text-primary-main">{' *'}</span> : ''}
              </>
            }
            value={changeValue}
            type={type}
            fullWidth
            error={!!error}
            helperText={error?.message}
            {...other}
            onChange={handleChange}
            // inputProps={{
            //   autoComplete: 'off',
            // }}
            // pattern={isNumber ? '/^[0-9۰-۹]+$/' : undefined}
            onWheel={(e) => {
              if (e.target.type === 'number') {
                e.preventDefault();
              }
              // e.target.blur();
              // setTimeout(() => e.target.focus(), 0);
            }}
          />
        );
      }}
    />
  );
}
