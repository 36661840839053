import { Box, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import agentRoleSelector from 'src/pages/agentRole/selector';
import agentSelector from 'src/pages/agent/selector';
import { useFormContext } from 'react-hook-form';
import RHFSelector from 'src/components/hook-form/RHFSelector';
import validation from '../validation';
import socialSelector from '../../../social/selector';
import RHFTextField from '../../../../components/hook-form/RHFTextField';

const AddableRowAgent = ({ handleAdd, last, length, handleRemove, basename }) => {
  const { setValue, watch } = useFormContext();

  const handleChange = (a, v) => {
    // console.log('* * * AddableRowAgent handleChange : ', { a, v });
    setValue(`${basename}.${validation.fieldNames.admin}`, undefined);
  };

  const watchAgent = watch(`${basename}.${validation.fieldNames.agent}`);
  // console.log('* * * AddableRowAgent :', { watchAgent });

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: 3,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
            <RHFSelector onChange={handleChange} name={`${basename}.${validation.fieldNames.agent}`} label={'مسئولیت'} freeSolo={!!watchAgent}/>
        </Grid>

        <Grid item xs={12} md={8}>
          <RHFSelector
            queryParams={{ agent_id: watchAgent?.value }}
            name={`${basename}.${validation.fieldNames.admin}`}
            label={'عامل'}
            enabled={!!watchAgent}
            freeSolo={!!watchAgent}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          display: 'flex',
          gap: 1,
        }}
      >
        {/* {length > 1 ? (
          <Box
            sx={{
              border: '2px solid',
              borderColor: 'grey.500',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '56px',
              height: '56px',
              borderRadius: '8px',
              cursor: 'pointer',
            }}
            onClick={handleRemove}
          >
            <RemoveIcon />
          </Box>
        ) : (
          ''
        )} */}

        {/* {length === 1 || last ? (
          <Box
            sx={{
              border: '2px solid',
              borderColor: 'grey.500',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '56px',
              height: '56px',
              borderRadius: '8px',
              cursor: 'pointer',
            }}
            onClick={handleAdd}
          >
            <AddIcon />
          </Box>
        ) : length > 1 ? (
          <Box
            sx={{
              width: '56px',
              height: '56px',
            }}
          />
        ) : (
          ''
        )} */}
      </Box>
    </Box>
  );
};

export default AddableRowAgent;
