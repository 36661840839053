/* eslint-disable*/
/* eslint-disable no-else-return */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable array-callback-return */
/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable dot-notation */
import axios from 'axios';
import { toast } from 'react-toastify';
// import { useSnackbar } from 'notistack';
// config
import { HOST_API } from '../config';
import { getClientMode, logoutUser } from '.';
import { ShowNotistack, useNotification } from './showNotistack';

import { PATH_AUTH, PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

const axiosInstanceV2 = axios.create({
  baseURL: `${HOST_API}/api/v2`,
});

axiosInstanceV2.interceptors.response.use(
  (response) => response,
  // (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
  (error) => {
    // const { enqueueSnackbar } = useSnackbar();
    // const { showError } = useNotification(); // Use the notification service

    if (error.response) {
      if (error.response.status !== 404) {
        if (error.response.status === 401) {
          logoutUser();
          window.location.href = PATH_AUTH.login;
        } else if (error.response.status === 403) {
          window.location.href = PATH_DASHBOARD.root;
        }else if (error.response.status === 408) {
          // window.location.href = PATH_DASHBOARD.root;
        } else {
          const errors = Object.values(error?.response?.data?.errors || {});
          if (errors?.length) {
            errors?.map((x) => {
              return toast.error(x?.[0]);
            });
          } else
            toast.error(
              error.response.data?.message || error.response.data?.error || 'مشکلی در ارتباط با سرور رخ داده است.'
            );
        }
      }
    } else {
      if (error?.message == 'Network Error') toast.error('مشکل اتصال به اینترنت.');
      else toast.error('مشکلی در ارتباط با سرور رخ داده است.');
    }
    // return Promise.reject(error);
    if (error.response) {
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
  // }
);

export default axiosInstanceV2;
