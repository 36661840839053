const api = {
  auth: {
    login: '/public/login',
  },
  omre: {
    base: '/',
  },
  caravan: {
    base: '/public/team',
    agent: '/public/team/agent',
    client: '/public/team/client',
    social: '/public/team/social',
    message: '/public/team/message',
    bank: '/public/team/bank',
  },
  notification: {
    base: '/client/notification',
  },
  caravanInfo: {
    base: '/client/team',
  },

  admin: {
    base: '/public',
  },
  role: {
    base: '/public/role',
  },
  permission: {
    base: '/public/permission',
  },
  agent: {
    base: '/public/agent',
  },
  university: {
    base: '/public/university',
  },
  universityCategory: {
    base: '/public/university_category',
  },
  travel: {
    base: '/public/travel',
  },
  travelHistory: { base: '/client/travel/history' },
  travelRegister: {
    base: '/public/travel_register',
  },
  form: {
    base: '/public/form',
    input: '/public/form/input',
    sample: '/public/form/sample',
    sampleForm: '/public/sample/form',
    sectionInput: '/public/form/section_input',
  },

  createFormBySample: {
    base: '/public/create_form_by_sample',
  },
  social: {
    base: '/public/social',
  },
  section: {
    base: '/public/form/section',
  },
  input: {
    base: '/public/form/input',
  },
  public: {
    form: {
      base: '/public/form',
    },
    section: {
      base: '/public/form/section',
    },
    input: {
      base: '/public/form/input',
    },
    travel: {
      base: '/public/travel',
    },
    travelRegister: {
      base: '/travel_register',
    },
  },
  client: {
    travel: {
      base: '/client/travel',
    },
    auth: {
      login: '/client/login',
      verify: '/client/verify_code',
    },
    word : "/client/word"
  },
  province: {
    base: '/public/province',
    byId: '/province',
  },
  city: {
    base: '/public/city',
    byId: '/city',
  },
};

export default api;
