import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import useAuth from 'src/hooks/useAuth';
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader, Button } from '@mui/material';
import { Link } from 'react-router-dom';
//
import { NavListRoot } from './NavList';
import { hasAccess } from '../../../permission/utiles';
import { PATH_AUTH } from '../../../routes/paths';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ navConfig, isCollapse = false, ...other }) {
  const [open, setOpen] = useState();
  const { isAuthenticated } = useAuth();

  const admin = useSelector((store) => store.admin.data);


  return (
    <Box
      sx={{
        bgcolor: 'grey.200',
      }}
      {...other}
    >
      {navConfig?.map((group) => (
        <List key={group.subheader} disablePadding sx={{ px: 2 }}>
          {!isAuthenticated ? (
            <>
              <NavListRoot
                key={'login-item'}
                list={{
                  title: 'ورود به سامانه',
                  path: "/",
                }}
                isCollapse={false}
                open={false}
                setOpen={(x) => null}
                depth={1}
                exact
              />
              {/* <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mb: 2,
                }}
              >
                <Button component={Link} to={`${PATH_AUTH.login}`} variant="contained" color="success">
                  {'ورود به سامانه'}
                </Button>
              </Box> */}
            </>
          ) : (
            ''
          )}

          {/* <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {group.subheader}
          </ListSubheaderStyle> */}

          {group.items.map((list, i) => (
            <>
              {hasAccess(list?.permission, list?.type) && (
                <>
                  <NavListRoot
                    key={list.title}
                    list={list}
                    isCollapse={isCollapse}
                    open={open?.[i]}
                    setOpen={(x) => setOpen({ [i]: x })}
                    depth={1}
                  />
                </>
              )}
            </>
          ))}
        </List>
      ))}
    </Box>
  );
}
