import RHFTextareaField from '../../../../components/hook-form/RHFTextareaField';

const FormInputText = ({ name, data, disabled, ...props }) => {
  return (
    <RHFTextareaField
      name={name}
      label={data?.label}
      disabled={disabled}
      inputProps={{
        autoComplete: 'off',
      }}
      {...props}
    />
  );
};

export default FormInputText;
