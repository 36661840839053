/* eslint-disable no-lonely-if */
import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import { adminProfile } from '../services/admin';
import { getAdminProfile, logoutAdminAction } from '../redux/slices/user';
import { store } from '../redux/store';
import api from '../services/api';
import { getClientMode, setClientMode } from '../utils';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: {},
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const mainStore = store;

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {
          setSession(accessToken);

          // const {data} = await adminProfile();
          // const { user } = response.data;
          const isClient = getClientMode();
          const userData = await mainStore.dispatch(getAdminProfile({ isClient }));

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: userData,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (phoneOrEmail, password) => {
    console.log('login start');
    const response = await axios.post(api.auth.login, {
      phoneOrEmail,
      password,
    });

    console.log({ response });
    const token = response.data?.token;
    // const accessToken = "kldlsdjlkfslkdjflksjdlkjsldjflsd";

    setSession(token);
    setClientMode(false);
    dispatch({
      type: 'LOGIN',
      payload: {
        user: {},
      },
    });
  };

  const publicLogin = async (codeMelli, phone) => {
    console.log('login start');
    const response = await axios.post(api.client.auth.login, {
      phone,
      code_melli: codeMelli,
    });

    console.log({ response });
    // const token = response.data?.token;
    // const accessToken = "kldlsdjlkfslkdjflksjdlkjsldjflsd";

    // setSession(token);
    // dispatch({
    //   type: 'LOGIN',
    //   payload: {
    //     user: {},
    //   },
    // });

    return response;
  };

  const handleAddSessionAndAfterLogin = async (token) => {
    setSession(token);
    setClientMode(true);
    // console.log({ response });
    const isClient = getClientMode();

    const userData = await mainStore.dispatch(getAdminProfile({ isClient }));

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {},
      },
    });
    dispatch({
      type: 'INITIALIZE',
      payload: {
        isAuthenticated: true,
        user: userData,
      },
    });
  }

  const publicVerify = async (codeMelli, code) => {
    console.log('verify start');
    const response = await axios.post(api.client.auth.verify, {
      verify_code: code,
      code_melli: codeMelli,
    });
    const helpRes = response.data;
    const token = response.data?.token;

    if (helpRes?.can_see_panel === 1 || helpRes?.can_see_panel === "1") {
      if (
        helpRes?.has_won_lottery === 1 ||
        helpRes?.has_won_lottery === 3
      ) {
        handleAddSessionAndAfterLogin(token);
      }
    } else {
      if (
        helpRes?.has_won_lottery === 1
        // helpRes?.has_won_lottery === 3
      ) {
        handleAddSessionAndAfterLogin(token);
      }
    }

    // setSession(token);
    // setClientMode(true);
    // console.log({ response });
    // dispatch({
    //   type: 'LOGIN',
    //   payload: {
    //     user: {},
    //   },
    // });
    return response;
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { token } = response.data;

    window.localStorage.setItem('accessToken', token);
    dispatch({
      type: 'REGISTER',
      payload: {
        user: {},
      },
    });
  };

  const logout = async () => {
    setSession(null);
    setClientMode(false);
    dispatch({ type: 'LOGOUT' });
    mainStore.dispatch(logoutAdminAction());
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        publicLogin,
        publicVerify,
        handleAddSessionAndAfterLogin
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
