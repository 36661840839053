import { useEffect, useRef } from "react";
import ModalLayout from "../ModalLayout";
import { Box, Typography } from "@mui/material";

const FireFoxModal = () => {
    const ModalLayoutQuestionInputRef = useRef();
    const handleQuestionModal = (params) => ModalLayoutQuestionInputRef.current.show((p) => !p, params, { disableBackDrop: true });

    useEffect(() => {
        handleQuestionModal()
    }, [])

    return (
        <>
            <ModalLayout ref={ModalLayoutQuestionInputRef}
                sx={{
                    backdropFilter: 'blur(3px)', // Apply blur effect to backdrop
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        maxWidth: '550px',

                        // display: { xs: 'block', md: 'flex' },
                        gap: 2,
                        bgcolor: 'background.paper',
                        border:"1px solid #222",
                        outline: 'none', 
                        borderRadius: 1,
                        p: 4,
                    }}
                >
                    <Typography sx={{
                        fontWeight: 700,
                        fontSize: 24,
                        textAlign: "center",
                        mb: 4
                    }}>
                        اطلاعیه
                    </Typography>

                    <Typography
                        sx={{
                            textAlign: "center",
                        }}
                    >
                        برای استفاده بهتر از سامانه ثبت نام از مرورگر کروم استفاده کنید.
                    </Typography>

                </Box>

            </ModalLayout>
        </>
    );
}

export default FireFoxModal;