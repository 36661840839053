import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { adminProfile, clientProfile } from '../../services/admin';

// Actions

// ----------------------------------------------------------------------

export const getAdminProfile = createAsyncThunk(
  'auth/get-admin',
  async ({ isClient }, { rejectWithValue, getState }) => {
    // dispatch(slice.actions.startLoading());
    try {
      let resData;
      if (isClient) {
        resData = await clientProfile();
      } else {
        resData = await adminProfile();
      }
      if (resData.data) resData.data.isClient = !!isClient;
      // dispatch(slice.actions.getUserData(data?.data));
      return {
        ...resData?.data,
        ...resData?.data?.["0"]
      };
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      console.log(error);
      rejectWithValue(error);
    }
  }
);
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  data: {},
};

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // END LOADING
    endLoading(state) {
      state.isLoading = true;
    },
    // END LOADING
    logoutAdmin(state) {
      state.isLoading = false;
      state.data = {};
    },

    // INIT
    getUserData(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getAdminProfile.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAdminProfile.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getAdminProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      });
  },
});

// Action creators
export const { logoutAdmin } = slice.actions;

// Export the logout function to be used in your app
export const logoutAdminAction = () => (dispatch) => {
  // Optional: Clear local storage or cookies here if you use them for authentication
  localStorage.removeItem('authToken'); // Example token removal
  dispatch(logoutAdmin());
};

// Reducer
export default slice.reducer;
