export const setRulesItem = () => {
    localStorage.setItem("acceptRules" , true)
}

export const checkAcceptRules = () => {
    const acceptRules = localStorage.getItem("acceptRules");

    if(acceptRules) return true

    return false
}