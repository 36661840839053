import { Route, Routes, useNavigate } from 'react-router';
import Enum from './enum';
import List from './List';
import Add from './Add';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const CaravanInfo = () => {
  // const urlParams = useParams();
  // const admin = useSelector((store) => store.admin.data);
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if(admin?.team){
  //     console.log({admin})
  //     navigate(`${Enum.routes.show}/${admin?.team?.id}`)
  //   }
  // },[admin])

  return (
    <Routes>
      {/* <Route path={`/send-notification`} element={<Add />} /> */}
      <Route path={`${Enum.routes.show}/:id`} element={<Add />} />
      {/* <Route path={`/`} element={<List />} /> */}
      <Route path={`/`} element={<Add />} />
    </Routes>
  );
};

export default CaravanInfo;
