import { ArrowBack } from '@mui/icons-material';
import { useMemo } from 'react';

// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

import axiosInstance from '../../../utils/axios';
import { travelTypeObject } from '../../../enumeration/index';

import {
  adminPermission,
  newsAgencyPermission,
  rolePermission,
  sellerPermission,
  ticketPermission,
  transactionPermission,
} from '../../../permission';
import { useQueryCustom } from '../../../utils/reactQueryHooks';
import useAuth from '../../../hooks/useAuth';
import api from '../../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { setTravel } from 'src/redux/slices/travel';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  team: getIcon('team'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  tournament: getIcon('tournament'),
  support: getIcon('support'),
  building: getIcon('building'),
};

const useNavConfigPublic = () => {
  const { isAuthenticated } = useAuth();

  const admin = useSelector((store) => store.admin.data);

  const dispatch = useDispatch();

  const gettingHistorySide = async () => {
    return axiosInstance.get(api.client.travel.base, {});
  };

  
  const addInRedux = (data) => {
    dispatch(setTravel(data?.data))
  }

  const sideQuery = useQueryCustom({
    name: `${api.client.travel.base}_get`,
    url: gettingHistorySide,
    onSuccess : addInRedux
  });
  console.log({ sideQuery });


  const gettingSide = async () => {
    return axiosInstance.get(api.public.travel.base, {});
  };

  // const sideQuery = useQueryCustom({
  //   name: `${api.public.travel.base}_get`,
  //   url: gettingSide,
  // });

  const sideTabs = sideQuery?.data?.data;
  // useMemo(() => {
  //   const tab = {
  //     [travelTypeObject.omre.value]: [],
  //     [travelTypeObject.atabat_aliat.value]: [],
  //     [travelTypeObject.other.value]: [],
  //     [travelTypeObject.tamato.value]: [],
  //   };

  //   if (sideQuery?.data?.data?.length) {
  //     for (let i = 0; i < sideQuery?.data?.data?.length; i += 1) {
  //       const curr = sideQuery?.data?.data?.[i];
  //       tab[curr.type] = tab[curr.type] || [];
  //       tab[curr.type] = curr.travels;
  //     }
  //   }
  //   return tab;
  // }, [sideQuery]);

  const atabatAliatTab = useMemo(() => {
    const array = [];

    const parameter = sideTabs?.[travelTypeObject.atabat_aliat.value]?.map((x) => {
      const forms = x.forms?.map((y) => ({
        title: y.name,
        path: `${PATH_DASHBOARD.form.root(travelTypeObject.atabat_aliat.value, x.id)}/${y.id}`,
      }));

      return {
        title: x.name,
        path: `${PATH_DASHBOARD.atabatAliat.root}/${x.id}`,
        children: [...forms],
      };
    });

    if (sideTabs?.[travelTypeObject.atabat_aliat.value]) array.push(...parameter);
    return array;
  }, [sideTabs]);

  const omreTab = useMemo(() => {
    const array = [];

    const parameter = sideTabs?.[travelTypeObject.omre.value]?.map((x) => {
      const forms = x.forms?.map((y) => ({
        title: y.name,
        path: `${PATH_DASHBOARD.form.root(travelTypeObject.omre.value, x.id)}/${y.id}`,
      }));

      return {
        title: x.name,
        path: `${PATH_DASHBOARD.omre.root}/${x.id}`,
        children: [...forms],
      };
    });

    if (sideTabs?.[travelTypeObject.omre.value]) array.push(...parameter);
    return array;
  }, [sideTabs]);

  const tamatoTab = useMemo(() => {
    const array = [];

    const parameter = sideTabs?.[travelTypeObject.tamato.value]?.map((x) => {
      const forms = x.forms?.map((y) => ({
        title: y.name,
        path: `${PATH_DASHBOARD.form.root(travelTypeObject.tamato.value, x.id)}/${y.id}`,
      }));

      return {
        title: x.name,
        path: `${PATH_DASHBOARD.tamato.root}/${x.id}`,
        children: [...forms],
      };
    });

    if (sideTabs?.[travelTypeObject.tamato.value]) array.push(...parameter);
    return array;
  }, [sideTabs]);

  const otherTab = useMemo(() => {
    const array = [];

    const parameter = sideTabs?.[travelTypeObject.other.value]?.map((x) => {
      const forms = x.forms?.map((y) => ({
        title: y.name,
        path: `${PATH_DASHBOARD.form.root(travelTypeObject.other.value, x.id)}/${y.id}`,
      }));

      return {
        title: x.name,
        path: `${PATH_DASHBOARD.other.root}/${x.id}`,
        children: [...forms],
      };
    });

    if (sideTabs?.[travelTypeObject.other.value]) array.push(...parameter);
    return array;
  }, [sideTabs]);

  // const buildSidebar = (key) => {
  //   const array = [];

  //   const parameter = sideTabs?.[travelTypeObject[key]?.value]?.map((x) => {
  //     // const forms = x.forms?.map((y) => ({
  //     //   title: y.name,
  //     //   path: `${PATH_DASHBOARD.form.root(travelTypeObject[key]?.value, x.id)}/${y.id}`,
  //     // }));

  //     return {
  //       title: x.name,
  //       path: `${PATH_DASHBOARD.atabatAliat.root}/${x.id}`,
  //       children: buildSideSubitems(key, x.id),
  //     };
  //   });

  //   if (sideTabs?.[travelTypeObject[key]?.value])
  //     array.push(...parameter, {
  //       title: 'سوابق',
  //       path: PATH_DASHBOARD.records.root(travelTypeObject[key]?.value),
  //       // permission: buildAccess(PATH_DASHBOARD.records.name, travelTypeObject[key]?.type)?.read,
  //     });
  //   return array;
  // };

  const sideTab = useMemo(() => {
    const final = [];
    if (!sideTabs) return;
    Object.values(sideTabs)?.map((x) => {
      console.log('* * * useMemo : ', { x });
      x?.map((y) => {
        const forms = y.forms?.map((z) => ({
          title: `${y?.name} - ${z.name}`,
          path: `${PATH_DASHBOARD.form.root(y.type, y.id)}/${z.name === "ثبت نام اولیه" ? "first" : z.id}`,
        }));
        if (forms?.length) final.push(...forms);
      });
    });

    if(admin?.team){
      final?.push({
        title: 'اطلاعات کاروان',
        path: PATH_DASHBOARD.caravanInfo.root,
        // icon: ICONS.dashboard,
        // isCollapse: true,
      })
    }

    return final;
  }, [sideTabs]);
  // console.log({ omreTab, sideTabs, sideTab });

  return [
    // GENERAL
    // ----------------------------------------------------------------------
    // {
    //   subheader: 'داشبورد',
    //   items: [
    //     { title: 'اعلانات', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
    // { title: 'اخبار', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    // { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
    // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
    // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
    // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
    //   ],
    // },
    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
      // subheader: '',
      items: [
        ...(isAuthenticated
          ? 
          (admin?.has_won_lottery === 3 && (admin?.can_see_panel !== 1 && admin?.can_see_panel !== "1")) ? [...(sideTab || [])] :
            [
              {
                title: 'عناوین',
                path: PATH_DASHBOARD.general.app,
                exact: true
                // icon: ICONS.dashboard,
                // isCollapse: true,
              },
              // {
              //   title: 'مدارک',
              //   path: PATH_DASHBOARD.document.root,
              //   // icon: ICONS.dashboard,
              //   // isCollapse: true,
              // },

              {
                title: 'پیام ها',
                path: '#', //PATH_DASHBOARD.notificationSection.root,
                // icon: ICONS.support,

                children: [
                  {
                    title: 'لیست پیام',
                    path: '#', //PATH_DASHBOARD.notification.root,
                  },
                  {
                    title: 'ارسال پیام',
                    path: '#', //PATH_DASHBOARD.sendNotification.root,
                  },
                ],
              },
              {
                title: 'اطلاعیه ها',
                path: PATH_DASHBOARD.notification.root,
              },
              admin?.team && {
                title: 'اطلاعات کاروان',
                path: PATH_DASHBOARD.caravanInfo.root,
                // icon: ICONS.dashboard,
                // isCollapse: true,
              },
              {
                title: 'سابقه سفرها',
                path: PATH_DASHBOARD.travelHistory.root,
                // icon: ICONS.dashboard,
                // isCollapse: true,
              },
              {
                title: 'سفر های جدید',
                path: PATH_DASHBOARD.newTravels.root,
                // icon: ICONS.support,

                children: sideTab,
                disabled: !sideTab?.length,
              },
              {
                title: 'نظرسنجی',
                path: '#', //PATH_DASHBOARD.general.app,
                // icon: ICONS.dashboard,
                // isCollapse: true,
                disabled: true,
              },
              {
                title: 'آموزش و آزمون',
                path: '#', //PATH_DASHBOARD.general.app,
                // icon: ICONS.dashboard,
                // isCollapse: true,
                disabled: true,
              },

              // ...(sideTab || []),
            ]?.filter(Boolean)
          : [...(sideTab || [])]),

        // ...omreTab?.children,
        // {
        //   // title: 'حج عمره',
        //   // path: PATH_DASHBOARD.omre.root,
        //   // children: [
        //   // ...omreTab,
        //   // {
        //   //   title: 'سوابق',
        //   //   path: PATH_DASHBOARD.records.root(travelTypeObject.omre.value),
        //   // },
        //   // ],
        // },
        // ...tamatoTab,
        // {
        //   title: 'حج تمتع',
        //   path: PATH_DASHBOARD.tamato.root,
        //   // icon: ICONS.analytics,
        //   children: [
        //     ...tamatoTab,
        //     // {
        //     //   title: 'سوابق',
        //     //   path: PATH_DASHBOARD.records.root(travelTypeObject.tamato.value),
        //     // },
        //   ],
        // },
        // ...atabatAliatTab,
        // {
        //   title: 'عتبات عالیات',
        //   path: PATH_DASHBOARD.atabatAliat.list,
        //   children: [
        //     ...atabatAliatTab,
        //     // {
        //     //   title: 'سوابق',
        //     //   path: PATH_DASHBOARD.records.root(travelTypeObject.atabat_aliat.value),
        //     // },
        //   ],
        // },
        // ...otherTab,
        // {
        //   title: 'سفرهای زیارتی دیگر',
        //   path: PATH_DASHBOARD.other.list,
        //   // icon: ICONS.team,
        //   children: [
        //     ...otherTab,
        //     // {
        //     //   title: 'سوابق',
        //     //   path: PATH_DASHBOARD.records.root(travelTypeObject.other.value),
        //     // },
        //   ],
        // },
      ],
    },
  ];
};

export default useNavConfigPublic;
