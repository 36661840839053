import { Autocomplete, Chip, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const RHFSelector = ({
  options = [],
  label,
  name,
  inputProps,
  InputProps,
  onChange,
  multiple = false,
  log,
  defaultValue,
  required,
  ...other
}) => {
  const { control } = useFormContext();

  if (log) console.log(`* * * RHFSelector ${label}: `, { options });

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        if (log) console.log('* * * RHFSelector :', { field });

        const availableOptions = options.filter((option) =>
          multiple
            ? !field.value?.some((selected) => selected.value === option.value)
            : field.value?.value !== option.value
        );

        return (
          <Autocomplete
            ref={field.ref}
            onBlur={field.onBlur}
            multiple={multiple}
            id={name}
            getOptionLabel={(option) => option.label || ""}
            noOptionsText="دیتایی یافت نشد !"
            loadingText="لطفا منتظر بمانید . . ."
            isOptionEqualToValue={(option, value) => {
              const normalizedValue = typeof value === 'string' ? { value } : value;
              return option?.value === normalizedValue?.value;
            }}
            onChange={(event, newValue) => {
              newValue = multiple
                ? newValue?.filter((x) => x.label || x.value)
                : newValue?.label || newValue?.value ? newValue : null;
              onChange?.(newValue);
              field.onChange(newValue);
            }}
            options={availableOptions}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option?.value}
                  size="small"
                  label={option.label}
                />
              ))
            }
            disableClearable
            value={multiple ? field.value || defaultValue || [] : field.value || defaultValue || null}
            renderOption={(props, option) => (
              <li
                {...props}
                style={{
                  pointerEvents: option.disabled ? 'none' : 'auto',
                  opacity: option.disabled ? 0.3 : 1,
                }}
              >
                {option.label}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                label={
                  <>
                    {label}
                    {required && <span className="text-primary-main"> *</span>}
                  </>
                }
                variant="outlined"
                error={error}
                helperText={error?.message}
                {...params}
                autoComplete="off"
                InputProps={{
                  ...inputProps,
                  ...params.InputProps,
                  ...InputProps,
                  inputProps: {
                    ...params.inputProps,
                    'aria-autocomplete': 'none',
                    autoComplete: 'off',
                  },
                  endAdornment: [
                    params?.InputProps?.endAdornment,
                    InputProps?.endAdornment,
                  ],
                }}
              />
            )}
            {...other}
          />
        );
      }}
    />
  );
};

export default RHFSelector;
