import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import { useMemo, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import CitySelector from '../../../../../components/selector/City';
import AcceptOrRejectWithQuestionsWithReason from '../../AcceptOrRejectWithQuestionsWithReason';

const FormInputLocationCity = ({ name, data, index, withGridBox, gridSize, ...props }) => {
  const { control, watch } = useFormContext();
  const parentProvinceName = index >= 0 ? data?.parentProvinceNames?.[index] : data?.parentProvinceName;

  const watchProvince = watch(`${parentProvinceName}`);
  console.log('* * * FormInputLocationCity : ', { index, name, data, watchProvince }, `${parentProvinceName}`);

  // const options=useMemo(()=>{
  //     return true// JSON.parse(data?.options||'{}');
  // },[data]);

  // console.log({options,data});
  // <RHFSelector options={options?.array||[]}  name={name} label={data?.label} />

  return (

    withGridBox ?
      <Grid item {...gridSize}>

        <Box>
          <CitySelector.Element
            geById={data?.client_inputs?.[index || 0]?.value}
            name={name}
            label={data?.label}
            provinceId={watchProvince?.value}
            required={data?.options?.required}
            {...props}
            InputProps={
              data?.need_confirm
                ? // && isHistory
                {
                  endAdornment: (
                    <InputAdornment position="start">
                      <AcceptOrRejectWithQuestionsWithReason {...data?.client_inputs?.[index || 0]} />
                    </InputAdornment>
                  ),
                }
                : ''
            }
          />
        </Box>
      </Grid>

      :
        <Box>
          <CitySelector.Element
            geById={data?.client_inputs?.[index || 0]?.value}
            name={name}
            label={data?.label}
            provinceId={watchProvince?.value}
            required={data?.options?.required}
            {...props}
            InputProps={
              data?.need_confirm
                ? // && isHistory
                {
                  endAdornment: (
                    <InputAdornment position="start">
                      <AcceptOrRejectWithQuestionsWithReason {...data?.client_inputs?.[index || 0]} />
                    </InputAdornment>
                  ),
                }
                : ''
            }
          />
        </Box>
  );
};

export default FormInputLocationCity;
